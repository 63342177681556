import React, { useState, useEffect } from "react";
import courseVideo from "./Component/Image/review.mp4";
import ReviewModal from "./ReviewModal";
import EditorMeta from "./Component/Meta/EditorMeta";

const Review = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <EditorMeta />
      <div className="relative">
        <video autoPlay loop muted className="object-cover w-full h-full">
          <source src={courseVideo} type="video/mp4" />
        </video>
        <div className="absolute inset-0 flex items-center justify-center">
          {/* Hide this div on mobile screens */}
          <div className="hidden sm:flex bg-white/60 rounded-md p-4 text-center flex-col items-center gap-6 w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[70%]">
            <h1 className="text-xl md:text-2xl lg:text-3xl font-extrabold">
              Our
              <br />
              <span style={{ color: "#F1B434" }}>
                Dream Adoration Online Compiler
              </span>
            </h1>
            <p className="text-base md:text-lg lg:text-lg font-bold">
              Master Coding with Our Interactive Online Compiler!
            </p>
            <button
              type="button"
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
              onClick={openModal}
            >
              Review
            </button>
          </div>
        </div>
      </div>
      {/* Display text below video for mobile view */}
      <div className="block md:hidden p-4 text-center bg-white/70 rounded-md">
        <h1 className="text-xxl font-extrabold" style={{ color: "#F1B434" }}>
          Dream Adoration Online Compiler
        </h1>
        <p className="text-base font-bold">
          Master Coding with Our Interactive Online Compiler!
        </p>
        <button
          type="button"
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
          onClick={openModal}
        >
          Review
        </button>
      </div>
      <ReviewModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default Review;
