import React, { useState, useEffect } from "react";
// import courseVideo from "./Image/Wardiere.mp4";
import courseVideo from "../Component/Image/Wardiere.mp4";
import Modal from "../Component/Modal";
import EditorMeta from "../Component/Meta/EditorMeta";
import Notepad from "./Notepad";

const CodeEditor = () => {
  const [htmlCode, setHtmlCode] = useState("");
  const [cssCode, setCssCode] = useState("");
  const [jsCode, setJsCode] = useState("");
  const [output, setOutput] = useState("");
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const runCode = () => {
    try {
      const finalCode = `
        <html>
          <head>
            <style>${cssCode}</style>
          </head>
          <body>
            ${htmlCode}
            <script>
              ${jsCode}
            </script>
          </body>
        </html>
      `;
      setOutput(finalCode);
      setError("");
    } catch (err) {
      setError("Code Error: " + err.message);
    }
  };

  // Automatically run code when htmlCode, cssCode, or jsCode changes
  useEffect(() => {
    runCode();
  }, [htmlCode, cssCode, jsCode]); // Dependencies: Runs when these states change

  return (
    <div>
      <EditorMeta />
      <div className="relative">
        <video autoPlay loop muted className="object-cover w-full h-full">
          <source src={courseVideo} type="video/mp4" />
        </video>
        <div className="absolute inset-0 flex items-center justify-center">
          {/* Hide this div on mobile screens */}
          <div className="hidden sm:flex bg-white/60 rounded-md p-4 text-center flex-col items-center gap-6 w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[70%]">
            <h1 className="text-xl md:text-2xl lg:text-3xl font-extrabold">
              Our
              <br />
              <span style={{ color: "#F1B434" }}>
                Dream Adoration Online Compiler
              </span>
            </h1>
            <p className="text-base md:text-lg lg:text-lg font-bold">
              Master Coding with Our Interactive Online Compiler!
            </p>
            <button
              type="button"
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
              onClick={openModal}
            >
              Registration
            </button>
          </div>
        </div>
      </div>
      {/* Display text below video for mobile view */}
      <div className="block md:hidden p-4 text-center bg-white/70 rounded-md">
        <h1 className="text-xxl font-extrabold" style={{ color: "#F1B434" }}>
          Dream Adoration Online Compiler
        </h1>
        <p className="text-base font-bold">
          Master Coding with Our Interactive Online Compiler!
        </p>
        <button
          type="button"
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
          onClick={openModal}
        >
          Registration
        </button>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
      <Notepad />
      <div
        className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6 m-4"
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          padding: "1rem",
          borderRadius: "10px",
        }}
      >
        {/* HTML Code Box */}
        <div className="flex flex-col">
          <h3
            className="text-xl font-bold mb-2"
            style={{
              color: "white",
              textAlign: "center",
              backgroundColor: "#d27b78",
              borderRadius: "1rem",
            }}
          >
            HTML
          </h3>
          <textarea
            className="border p-2 h-96 resize-none rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-300"
            value={htmlCode}
            onChange={(e) => setHtmlCode(e.target.value)}
            placeholder="Write your HTML code here..."
          />
        </div>

        {/* CSS Code Box */}
        <div className="flex flex-col">
          <h3
            className="text-xl font-bold  mb-2"
            style={{
              color: "white",
              textAlign: "center",
              backgroundColor: "#337ab7",
              borderRadius: "1rem",
            }}
          >
            CSS
          </h3>
          <textarea
            className="border p-2 h-96 resize-none rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={cssCode}
            onChange={(e) => setCssCode(e.target.value)}
            placeholder="Write your CSS code here..."
          />
        </div>

        {/* JavaScript Code Box */}
        <div className="flex flex-col">
          <h3
            className="text-xl font-bold mb-2"
            style={{
              color: "white",
              textAlign: "center",
              backgroundColor: "#f0ad4e",
              borderRadius: "1rem",
            }}
          >
            JavaScript
          </h3>
          <textarea
            className="border p-2 h-96 resize-none rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
            value={jsCode}
            onChange={(e) => setJsCode(e.target.value)}
            placeholder="Write your JavaScript code here..."
          />
        </div>
      </div>

      {/* Error Message */}
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}

      {/* Output Box */}
      <div
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          padding: "1rem",
          borderRadius: "10px",
        }}
      >
        <h3
          className="text-xl font-bold mb-2 text-center"
          style={{
            color: "white",
            textAlign: "center",
            backgroundColor: "#5cb85c",
            borderRadius: "1rem",
          }}
        >
          Output
        </h3>
        <iframe
          title="output"
          srcDoc={output}
          className="w-full h-[500px]  border rounded-lg"
        />
      </div>
    </div>
  );
};

export default CodeEditor;
