import React, { useState, useEffect } from "react";
import Preloader from "./Preloader";
import Toast from "./Toast"; // Import the Toast component
import $ from "jquery";

function FreeDemoRegistration() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course: "",
    state: "",
    uniqueKey: "",
  });

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [toast, setToast] = useState({ show: false, message: "", type: "" }); // State for toast

  useEffect(() => {
    fetchUniqueKey();
  }, []);

  const fetchUniqueKey = async () => {
    try {
      const response = await fetch("http://example.com/getUniqueKey");
      const data = await response.json();
      setFormData((prevData) => ({
        ...prevData,
        uniqueKey: data.key,
      }));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching unique key:", error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    for (const key in formData) {
      if (!formData[key].trim()) {
        errors[key] = "This field is required";
      }
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(formData.email) && formData.email.trim() !== "") {
      errors.email = "Please enter a valid email address.";
    }

    const namePattern = /^[a-zA-Z,\s]{0,20}$/;
    if (
      !namePattern.test(formData.name.trim()) &&
      formData.name.trim() !== ""
    ) {
      errors.name = "20 characters, no specials or numbers.";
    }

    const statePattern = /^[a-zA-Z,\s]{0,18}$/;
    if (
      !statePattern.test(formData.state.trim()) &&
      formData.state.trim() !== ""
    ) {
      errors.state = "18 characters, no specials or numbers.";
    }

    const coursePattern = /^[a-zA-Z,\s]{0,25}$/;
    if (
      !coursePattern.test(formData.course.trim()) &&
      formData.course.trim() !== ""
    ) {
      errors.course = "25 characters, no specials or numbers.";
    }

    const numberPattern = /^\+?\d{0,11}$/;
    if (
      !numberPattern.test(formData.number.trim()) &&
      formData.number.trim() !== ""
    ) {
      errors.number = "Max 12 characters, only numbers and one '+'.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    }

    if (
      formData.name !== "" &&
      formData.email !== "" &&
      formData.number !== "" &&
      formData.course !== "" &&
      formData.state !== ""
    )
      setLoading(true);
    {
      $.ajax({
        url: "/webservices/modal-form-data.php",
        type: "POST",
        data: {
          name: formData.name,
          email: formData.email,
          number: formData.number,
          course: formData.course,
          state: formData.state,
          action: "free_learning",
        },

        success: function (response) {
          setLoading(false);
          setToast({ show: true, message: response, type: "success" });
          setTimeout(() => {
            setToast({ show: false, message: "", type: "" }); // Hide the toast after 5 seconds
            window.location.reload();
          }, 3000); // Reload the page after 3 seconds
        },
        error: function (xhr, status, error) {
          setLoading(false);
          setToast({
            show: true,
            message: "Please try again after some time !",
            type: "error",
          });
        },
      });
    }
  };

  return (
    <section className="mt-0 md:mt-30">
      {loading ? ( // Show preloader if loading is true
        <Preloader />
      ) : (
        <div
          className="px-6 py-8 mx-auto md:h-screen lg:py-0"
          style={{ width: "-webkit-fill-available", display: "inline-table" }}
        >
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Register for internship
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <span className="text-red-500">{errors.name}</span>
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <span className="text-red-500">{errors.email}</span>
                </div>
                <div>
                  <input
                    type="text"
                    name="state"
                    id="state"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="State"
                    required
                    value={formData.state}
                    onChange={handleChange}
                  />
                  <span className="text-red-500">{errors.state}</span>
                </div>
                <div>
                  <input
                    type="text"
                    name="number"
                    id="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Number"
                    required
                    value={formData.number}
                    onChange={handleChange}
                  />
                  <span className="text-red-500">{errors.number}</span>
                </div>
                <div>
                  <input
                    type="text"
                    name="course"
                    id="course"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Course"
                    required
                    value={formData.course}
                    onChange={handleChange}
                  />
                  <span className="text-red-500">{errors.course}</span>
                </div>
                <button
                  type="submit"
                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      {toast.show && <Toast message={toast.message} type={toast.type} />}{" "}
      {/* Render toast if show is true */}
    </section>
  );
}

export default FreeDemoRegistration;
