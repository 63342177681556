import React, { useState, useEffect } from "react";
import Preloader from "./Component/Preloader";
import $ from "jquery";
import Toast from "./Component/Toast";
// import ReCAPTCHA from "react-google-recaptcha";

export default function ReviewModal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    college: "",
    review: "",
    state: "",
    uniqueKey: "",
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [toast, setToast] = useState({ show: false, message: "", type: "" }); // State for toast

  useEffect(() => {
    fetchUniqueKey();
  }, []);

  // Function to fetch the unique key from the server
  const fetchUniqueKey = async () => {
    try {
      const response = await fetch("http://example.com/getUniqueKey");
      const data = await response.json();
      setFormData((prevData) => ({
        ...prevData,
        uniqueKey: data.key,
      }));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching unique key:", error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear the error message if the field is not empty
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    // Check for empty fields
    for (const key in formData) {
      if (!formData[key].trim()) {
        errors[key] = "This field is required";
      }
    }

    const namePattern = /^[a-zA-Z,\s]{0,100}$/;
    if (
      !namePattern.test(formData.name.trim()) &&
      formData.name.trim() !== ""
    ) {
      errors.name = "100 characters, no specials or colleges.";
    }

    const statePattern = /^[a-zA-Z,\s]{0,10}$/;
    if (
      !statePattern.test(formData.state.trim()) &&
      formData.state.trim() !== ""
    ) {
      errors.state = "100 characters, no specials or colleges.";
    }

    const reviewPattern = /^[a-zA-Z,\s]{0,25}$/;
    if (
      !reviewPattern.test(formData.review.trim()) &&
      formData.review.trim() !== ""
    ) {
      errors.review = "25 characters, no specials or colleges.";
    }

    const collegePattern = /^\+?\d{0,11}$/;
    if (
      !collegePattern.test(formData.college.trim()) &&
      formData.college.trim() !== ""
    ) {
      errors.college = "Max 12 characters, only colleges and one '+'.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      console.log("Form submitted:", formData);
      onClose();
    }

    if (
      formData.name !== "" &&
      formData.gender !== "" &&
      formData.college !== "" &&
      formData.review !== "" &&
      formData.state !== ""
    )
      setLoading(true);
    {
      $.ajax({
        url: "/webservices/modal-form-data.php",
        type: "POST",
        data: {
          name: formData.name,
          gender: formData.gender,
          college: formData.college,
          review: formData.review,
          state: formData.state,
          action: "free_learning",
        },
        success: function (response) {
          setLoading(false);
          setToast({ show: true, message: response, type: "success" });
          setTimeout(() => {
            setToast({ show: false, message: "", type: "" }); // Hide the toast after 5 seconds
            window.location.reload();
          }, 3000); // Reload the page after 3 seconds
        },
        error: function (xhr, status, error) {
          setLoading(false);
          setToast({
            show: true,
            message: "Please try again after some time !",
            type: "error",
          });
        },
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fadeOut">
      <div>
        {loading ? ( // Show preloader if loading is true
          <Preloader />
        ) : (
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto mt-12 mx-auto max-w-3xl lg:w-[27rem]">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-4 border-b border-solid border-blueGray-200 rounded-t">
                  <h1 className="text-3xl font-semibold">Student's&nbsp;</h1>
                  <h2 className="text-3xl font-semibold">Review Form</h2>
                </div>
                {/*body*/}
                <form
                  className="space-y-4 md:space-y-6 text-left"
                  action="#"
                  style={{ margin: "1rem" }}
                  onSubmit={handleSubmit}
                >
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Name"
                      required
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {/* Error message for name field */}
                    <span style={{ color: "red" }}>{errors.name}</span>
                  </div>
                  <div>
                    <label
                      htmlFor="gender"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Gender
                    </label>
                    <select
                      name="gender"
                      id="gender"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                      value={formData.gender} // Bind the state for the gender value
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {/* Error message for gender field */}
                    <span style={{ color: "red" }}>{errors.gender}</span>
                  </div>

                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      College Name
                    </label>
                    <input
                      type="text"
                      name="gender"
                      id="gender"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="College Name"
                      required
                      value={formData.gender}
                      onChange={handleChange}
                    />
                    {/* Error message for gender field */}
                    <span style={{ color: "red" }}>{errors.gender}</span>
                  </div>

                  <div>
                    <label
                      htmlFor="review"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Review
                    </label>
                    <input
                      type="textarea"
                      name="state"
                      id="state"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Textarea"
                      required
                      value={formData.state}
                      onChange={handleChange}
                    />
                    {/* Error message for review field */}
                    <span style={{ color: "red" }}>{errors.state}</span>
                  </div>

                  <div>
                    <label
                      htmlFor="rating"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Rating
                    </label>
                    <select
                      name="rating"
                      id="rating"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                      value={formData.rating} // Bind the state for the rating value
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select Rating
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                    {/* Error message for rating field */}
                    <span style={{ color: "red" }}>{errors.rating}</span>
                  </div>

                  {/*footer*/}

                  <div className="flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={onClose}
                    >
                      Close
                    </button>

                    {/* <ReCAPTCHA
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={onChange}
                  /> */}

                    <input
                      type="hidden"
                      name="uniqueKey"
                      value={formData.uniqueKey}
                    />
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                      // disabled={!veryfied}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {toast.show && <Toast message={toast.message} type={toast.type} />}{" "}
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </div>
    </div>
  );
}
