import React from "react";

import { Link } from "react-router-dom";
import MockTestMeta from "./FAQ/MockTestMeta";
function Mocktest() {
  return (
    <div>
      <MockTestMeta />

      <div
        class="px-3 md:lg:xl:px-40   border-t border-b py-20 bg-opacity-10"
        style={{
          backgroundImage:
            "url('https://www.toptal.com/designers/subtlepatterns/uploads/dot-grid.png')",
        }}
      >
        <div
          class="grid grid-cols-1 md:lg:xl:grid-cols-3 group bg-white shadow-xl shadow-neutral-100 border"
          style={{ borderTopRightRadius: "1rem", borderTopLeftRadius: "1rem" }}
        >
          <Link to="/html-mock-test">
            <div
              class="p-10 flex flex-col items-center text-center group md:lg:xl:border-r hover:bg-slate-50 cursor-pointer"
              style={{ borderRadius: "1rem" }}
            >
              <span class="p-5 rounded-full bg-teal-500 text-white shadow-lg shadow-teal-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  />
                </svg>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                HTML MOCK TEST
              </p>
              <p class="mt-2 text-sm text-slate-500">
                Experience the transformative power of HTML with Dream
                Adoration's test. Wish the students success as they embark on
                their HTML journey. Gain valuable insights and direction for
                advancing your skills in HTML development through this test.
              </p>
            </div>
          </Link>

          <Link to="/css-mock-test">
            <div class="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-orange-500 text-white shadow-lg shadow-orange-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.5"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                CSS MOCK TEST
              </p>
              <p class="mt-2 text-sm text-slate-500">
                Start your CSS journey with Dream Adoration's test, uncovering
                insights and guidance for success. Discover your standing and
                advance your skills in CSS development. Take the test to pave
                the way for growth and proficiency.
              </p>
            </div>
          </Link>

          <Link to="/javascript-mock-test">
            <div class="p-10 flex flex-col items-center text-center group   md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-yellow-500 text-white shadow-lg shadow-yellow-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                  />
                </svg>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                JAVA SCRIPT MOCK TEST
              </p>
              <p class="mt-2 text-sm text-slate-500">
                Elevate your JavaScript proficiency with Dream Adoration's
                insightful test. Gauge your skills, receive tailored guidance,
                and accelerate your journey to mastery. Take the test to unlock
                new possibilities and excel in JavaScript development.
              </p>
            </div>
          </Link>

          <Link to="/php-mock-test">
            <div class="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-red-500 text-white shadow-lg shadow-red-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                PHP MOCK TEST
              </p>
              <p class="mt-2 text-sm text-slate-500">
                Unlock your PHP potential with Dream Adoration's test, gaining
                insights and guidance for success. Assess your skills, chart
                your course, and excel in PHP development. Take the test to
                propel your journey towards expertise and achievement.
              </p>
            </div>
          </Link>

          <Link to="/react-mock-test">
            <div class="p-10 flex flex-col items-center text-center group   md:lg:xl:border-r hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-lime-500 text-white shadow-lg shadow-lime-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                  />
                </svg>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                REACT JS MOCK TEST
              </p>
              <p class="mt-2 text-sm text-slate-500">
                Master React.js with Dream Adoration's comprehensive test.
                Assess your skills, receive personalized recommendations, and
                advance your expertise in React development. Take the test to
                propel your career forward and unleash your full potential in
                React.js.
              </p>
            </div>
          </Link>

          <Link to="/mysql-mock-test">
            <div class="p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-indigo-500 text-white shadow-lg shadow-indigo-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                MYSQL MOCK TEST
              </p>
              <p class="mt-2 text-sm text-slate-500">
                Unlock your MySQL potential with Dream Adoration's insightful
                test. Evaluate your knowledge, uncover areas for improvement,
                and chart a path to success in MySQL. Take the test to elevate
                your database skills and excel in MySQL development.
              </p>
            </div>
          </Link>

          <Link to="/python-mock-test">
            <div class="p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-teal-500 text-white shadow-lg shadow-teal-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  />
                </svg>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                Python MOCK TEST
              </p>
              <p class="mt-2 text-sm text-slate-500">
                Master Python with Dream Adoration's expert-designed test!
                Assess your knowledge, identify areas to improve, and gain the
                confidence to excel in Python programming. Take the test now and
                step up your coding journey toward success.
              </p>
            </div>
          </Link>

          <Link to="/bootstrap-mock-test">
            <div class="p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-yellow-500 text-white shadow-lg shadow-yellow-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                  />
                </svg>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                BOOTSTRAP MOCK TEST
              </p>
              <p class="mt-2 text-sm text-slate-500">
                Elevate your Bootstrap skills with Dream Adoration's exclusive
                test! Assess your expertise, discover improvement opportunities,
                and unlock your potential in responsive web design. Take the
                test today and build stunning, professional websites with ease.
              </p>
            </div>
          </Link>

          <Link to="/telwind-css-mock-test">
            <div class="p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-red-500 text-white shadow-lg shadow-red-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                TELWIND CSS MOCK TEST
              </p>
              <p class="mt-2 text-sm text-slate-500">
                Unleash your Tailwind CSS potential with Dream Adoration's
                tailored test! Evaluate your knowledge, refine your skills, and
                master the art of utility-first web design. Take the test now to
                create modern, responsive designs with ease.
              </p>
            </div>
          </Link>
        </div>

        <div
          class="w-full   bg-indigo-600 shadow-xl shadow-indigo-200 py-9 px-9 flex justify-between items-center"
          style={{
            borderBottomRightRadius: "1rem",
            borderBottomLeftRadius: "1rem",
          }}
        >
          <p class=" text-white">
            {" "}
            <span class="text-xl font-medium">
              Still Confused ?
            </span> <br />{" "}
            <span class="text-sm">
              Book For Free Career Consultation Today !{" "}
            </span>
          </p>
          <button
            class="px-1.5 py-2  font-medium text-slate-700 shadow-xl  hover:bg-white duration-150  bg-yellow-400"
            style={{ marginLeft: "1rem", borderRadius: "0.5rem" }}
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send/?phone=9097951772&text&type=phone_number&app_absent=0",
                "_blank"
              );
            }}
          >
            BOOK AN APPOINTMENT{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Mocktest;
