import React, { useState } from "react";

function Notepad() {
  const [isNotepadOpen, setIsNotepadOpen] = useState(false);
  const [content, setContent] = useState(
    localStorage.getItem("notepadContent") || ""
  );

  const handleOpenNotepad = () => {
    setIsNotepadOpen(true);
  };

  const handleCloseNotepad = () => {
    setIsNotepadOpen(false);
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
    localStorage.setItem("notepadContent", e.target.value);
  };

  return (
    <div className="bg-gray-100 flex flex-col items-center justify-center p-4">
      {!isNotepadOpen ? (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleOpenNotepad}
        >
          Open Notepad
        </button>
      ) : (
        <div className="relative w-full max-w-2xl">
          <button
            className="absolute top-2 right-6 text-gray-400 hover:text-red-900  text-3xl"
            onClick={handleCloseNotepad}
          >
            &times;
          </button>
          <textarea
            className="w-full h-96 p-4 text-gray-800 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={content}
            onChange={handleContentChange}
            placeholder="Type here..."
          ></textarea>
        </div>
      )}
    </div>
  );
}

export default Notepad;
