import React, { useState, useEffect } from "react";

const questions = [
  {
    id: 1,
    question: "What is Python?",
    options: [
      "A high-level programming language",
      "A type of snake",
      "A database management tool",
      "A type of web browser",
    ],
    correctAnswer: "A high-level programming language",
  },
  {
    id: 2,
    question: "Which keyword is used to define a function in Python?",
    options: ["def", "func", "function", "define"],
    correctAnswer: "def",
  },
  {
    id: 3,
    question: "What is the output of: print(2 ** 3)?",
    options: ["8", "6", "9", "4"],
    correctAnswer: "8",
  },
  {
    id: 4,
    question: "Which data type is used to store true or false values?",
    options: ["bool", "int", "str", "float"],
    correctAnswer: "bool",
  },
  {
    id: 5,
    question: "Which function is used to get input from the user in Python?",
    options: ["input()", "get()", "read()", "scan()"],
    correctAnswer: "input()",
  },
  {
    id: 6,
    question: "What is the output of: len('Python')?",
    options: ["6", "7", "5", "None"],
    correctAnswer: "6",
  },
  {
    id: 7,
    question: "Which keyword is used to create a class in Python?",
    options: ["class", "create", "object", "struct"],
    correctAnswer: "class",
  },
  {
    id: 8,
    question: "Which module in Python is used to work with JSON data?",
    options: ["json", "os", "sys", "data"],
    correctAnswer: "json",
  },
  {
    id: 9,
    question: "What does the 'break' statement do in Python?",
    options: [
      "Exits a loop",
      "Skips an iteration",
      "Restarts the loop",
      "Ends the program",
    ],
    correctAnswer: "Exits a loop",
  },
  {
    id: 10,
    question: "How do you start a comment in Python?",
    options: ["#", "//", "/*", "--"],
    correctAnswer: "#",
  },
  {
    id: 11,
    question: "What is a Python list?",
    options: [
      "A collection of ordered, mutable items",
      "A collection of unique items",
      "A collection of key-value pairs",
      "A collection of immutable items",
    ],
    correctAnswer: "A collection of ordered, mutable items",
  },
  {
    id: 12,
    question: "Which method is used to add an item to a list?",
    options: ["append()", "add()", "insert()", "extend()"],
    correctAnswer: "append()",
  },
  {
    id: 13,
    question: "What is the output of: 5 == 5?",
    options: ["True", "False", "Error", "None"],
    correctAnswer: "True",
  },
  {
    id: 14,
    question: "Which keyword is used to handle exceptions in Python?",
    options: ["try", "catch", "handle", "except"],
    correctAnswer: "except",
  },
  {
    id: 15,
    question: "What does the 'range()' function do?",
    options: [
      "Generates a sequence of numbers",
      "Generates random numbers",
      "Sorts a list of numbers",
      "Finds the maximum value in a list",
    ],
    correctAnswer: "Generates a sequence of numbers",
  },
  {
    id: 16,
    question: "Which keyword is used to import a module in Python?",
    options: ["import", "module", "include", "load"],
    correctAnswer: "import",
  },
  {
    id: 17,
    question: "How do you create a tuple in Python?",
    options: ["(1, 2, 3)", "[1, 2, 3]", "{1, 2, 3}", "<1, 2, 3>"],
    correctAnswer: "(1, 2, 3)",
  },
  {
    id: 18,
    question: "What does the 'is' keyword do?",
    options: [
      "Checks if two objects are the same instance",
      "Checks if two values are equal",
      "Checks if an item exists in a list",
      "Checks if a variable is defined",
    ],
    correctAnswer: "Checks if two objects are the same instance",
  },
  {
    id: 19,
    question:
      "Which method is used to remove whitespace from the beginning and end of a string?",
    options: ["strip()", "trim()", "remove()", "clean()"],
    correctAnswer: "strip()",
  },
  {
    id: 20,
    question: "Which Python data structure is immutable?",
    options: ["Tuple", "List", "Dictionary", "Set"],
    correctAnswer: "Tuple",
  },
  {
    id: 21,
    question: "Which method is used to split a string into a list?",
    options: ["split()", "divide()", "slice()", "partition()"],
    correctAnswer: "split()",
  },
  {
    id: 22,
    question: "What is a lambda function in Python?",
    options: [
      "An anonymous function",
      "A function that runs indefinitely",
      "A function defined inside a class",
      "A function that handles exceptions",
    ],
    correctAnswer: "An anonymous function",
  },
  {
    id: 23,
    question: "How do you create a set in Python?",
    options: ["{1, 2, 3}", "[1, 2, 3]", "(1, 2, 3)", "<1, 2, 3>"],
    correctAnswer: "{1, 2, 3}",
  },
  {
    id: 24,
    question: "Which operator is used to check if a value exists in a list?",
    options: ["in", "exists", "has", "check"],
    correctAnswer: "in",
  },
  {
    id: 25,
    question: "Which Python keyword is used to create a generator?",
    options: ["yield", "return", "generate", "next"],
    correctAnswer: "yield",
  },
];

const PythonTest = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(
    new Array(questions.length).fill("")
  );
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds
  const [attemptedQuestions, setAttemptedQuestions] = useState(0);
  const [timerColor, setTimerColor] = useState("#00FF00"); // Initial color green
  const [timerPaused, setTimerPaused] = useState(false); // State to track timer pause/resume

  useEffect(() => {
    // Scroll to the element with the ID 'css' when the component mounts
    const element = document.getElementById("css");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []); // Empty dependency array ensures this effect runs only once after the component mounts

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!timerPaused) {
        // Only decrement time if timer is not paused
        setTimeLeft(timeLeft - 1);

        // Update timer color based on remaining time
        if (timeLeft <= 300 && timeLeft > 120) {
          setTimerColor("#FFFF00"); // Yellow
        } else if (timeLeft <= 120) {
          setTimerColor("darkred"); // Red
        }

        // Check if time is up
        if (timeLeft === 0) {
          setShowScore(true);
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, timerPaused]);

  const handleOptionSelect = (option) => {
    // Check if the selected option is different from the previously selected option
    if (selectedOptions[currentQuestion] !== option) {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions[currentQuestion] = option;
      setSelectedOptions(newSelectedOptions);

      if (!selectedOptions[currentQuestion]) {
        // Increment attempted questions only if the current question didn't have an attempt before
        setAttemptedQuestions(attemptedQuestions + 1);
      }

      if (option === questions[currentQuestion].correctAnswer) {
        setScore(score + 1);
      }
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowScore(true);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handlePauseResumeTimer = () => {
    setTimerPaused(!timerPaused); // Toggle timer pause/resume state
  };

  return (
    <div
      className="container mx-auto p-4"
      id="css"
      style={{
        backgroundColor: "#F4F4F6",
        margin: "5rem",
        borderRadius: "15px",
      }}
    >
      {!showScore ? (
        <div
          className="relative isolate overflow-hidden bg-gray-900"
          style={{ borderRadius: "10px", color: "white" }}
        >
          <h1
            className="text-3xl font-bold text-center mb-8"
            style={{ margin: "1rem" }}
          >
            PYTHON MOCK TEST
            <br />
            <span style={{ color: "darkred", fontSize: "22px" }}>
              Dream Adoration
            </span>
          </h1>
          <div style={{ margin: "2rem" }}>
            <h2 className="text-2xl font-medium mb-4">
              Question {currentQuestion + 1} of {questions.length}
            </h2>
            <h3 className="text-lg font-semibold mb-2">
              {questions[currentQuestion].question}
            </h3>
            {questions[currentQuestion].options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestion] === option}
                    onChange={() => handleOptionSelect(option)}
                    className="form-radio h-5 w-5 text-primary-600"
                  />
                  <span className="ml-2 ">{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-around">
            <button
              onClick={handlePreviousQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-3 rounded mt-5"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded mt-5"
            >
              Next
            </button>
            <button
              onClick={handlePauseResumeTimer}
              className="bg-green-600 hover:bg-green-700 text-black font-bold py-2 px-4 rounded mt-5"
            >
              {timerPaused ? "Resume" : "Pause"}
            </button>
          </div>
          <div
            className=" mt-1 text-center"
            style={{ color: timerColor, fontWeight: "bold" }}
          >
            Time Left: {Math.floor(timeLeft / 60)}:
            {(timeLeft % 60).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}
          </div>
          <div
            className=" mt-1 text-center"
            style={{ fontWeight: "bold", padding: "10px" }}
          >
            Attempted Questions: {attemptedQuestions} / {questions.length}
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <h3 className="text-lg font-medium mb-2">
            Your Score: {score}/{questions.length}
          </h3>
        </div>
      )}
    </div>
  );
};

export default PythonTest;
